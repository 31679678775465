@import '../../../app/utils/common/colors.scss';


.testimonial {
    width: 100%;
    background-color: $white;
    font-family: 'M PLUS 1';
    animation: fadeIn 0.6s ease forwards;


    .testimonial__title {
        font-size: 0.938vw;
        font-weight: bold;
        letter-spacing: 0.26vw;
        margin-bottom: 0.521vw;
        text-align: center;
        color: $orange;
        animation: fadeIn 0.5s ease forwards;
    }

    .testimonial__subtitle {
        font-size: 2.344vw;
        color: $black;
        text-align: center;
        margin-bottom: 1.042vw;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        animation: fadeInUp 0.7s ease forwards;
    }

    .testimonial_animation-information {
        display: flex;
        width: 80%;
        justify-content: center;
        align-items: center;

        .testimonial__animation {
            width: 46.875vw;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 1.042vw;
            animation: slideInRight 0.8s ease forwards;
        }

        .testimonial__content {
            width: 30%;
            justify-content: flex-start;
            animation: fadeInLeft 0.8s ease forwards;

            .testimonial__quote {
                font-size: 1.354vw;
                font-weight: 600;
                margin-bottom: 0.521vw;
                animation: fadeIn 0.9s ease forwards;
            }

            .testimonial__description {
                font-size: 0.99vw;
                color: $gray;
                margin: 0.521vw 0 1.302vw;
                animation: fadeIn 1s ease forwards;
            }

            .testimonial__images {
                display: flex;
                margin-bottom: 0.521vw;

                .testimonial__image {
                    width: 1.563vw;
                    height: 1.563vw;
                    margin: 0 0.26vw;
                    border-radius: 50%;
                    opacity: 0;
                    transition: opacity 0.5s ease;

                    &.visible {
                        opacity: 1;
                    }

                    &.hidden {
                        opacity: 0;
                    }
                }
            }

            .testimonial__author {
                font-size: 0.833vw;
                font-weight: 500;
                color: $gray;
                animation: fadeIn 1.1s ease forwards;
            }
        }
    }

    .darkstar-img-tt {
        position: relative;
        bottom: 5.625vw;
        left: 10.885vw;
    }
}


@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(1.042vw);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-1.042vw);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInRight {
    from {
        opacity: 0;
        transform: translateX(30px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes enterAnimation {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes leaveAnimation {
    0% {
        opacity: 1;
        transform: scale(1);
    }

    100% {
        opacity: 0;
        transform: scale(1.5);
    }
}


@media (max-width: 900px) {
    .testimonial {
        .testimonial__title {
            font-size: 2.938vw;
        }

        .testimonial__subtitle {
            font-size: 3.25vw;
        }

        .testimonial_animation-information {
            width: 100%;
            flex-direction: column-reverse;

            .testimonial__animation {
                display: none;
            }

            .testimonial__content {
                width: 100%;
                justify-content: center;
                text-align: center;
                margin-bottom: 3vw;

                .testimonial__quote {
                    font-size: 2.25vw;
                }

                .testimonial__description {
                    font-size: 2.229vw;
                    padding: 10px;
                }

                .testimonial__images {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .testimonial__image {
                        width: 5.563vw;
                        height: 5.563vw;
                        margin: 0 0.26vw;
                        border-radius: 50%;
                        transition: transform 0.5s ease, opacity 0.5s ease;
                        opacity: 0.7;
                    }
                }

                .testimonial__author {
                    font-size: 0.833vw;
                    font-weight: 500;
                    color: $gray;
                    animation: fadeIn 1.1s ease forwards;
                }
            }
        }

        .darkstar-img-tt {
            display: none;
        }
    }
}

@media (max-width: 638px) {
    .testimonial {
        .testimonial__title {
            font-size: 2.938vw;
        }

        .testimonial__subtitle {
            font-size: 3.25vw;
        }

        .testimonial_animation-information {
            width: 100%;
            flex-direction: column-reverse;

            .testimonial__animation {
                display: none;
            }

            .testimonial__content {
                width: 100%;
                justify-content: center;
                text-align: center;
                margin-bottom: 3vw;

                .testimonial__quote {
                    font-size: 2.25vw;
                }

                .testimonial__description {
                    font-size: 2.229vw;
                    padding: 10px;
                }

                .testimonial__images {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .testimonial__image {
                        width: 5.563vw;
                        height: 5.563vw;
                        margin: 0 0.26vw;
                        border-radius: 50%;
                        transition: transform 0.5s ease, opacity 0.5s ease;
                        opacity: 0.7;
                    }
                }

                .testimonial__author {
                    font-size: 0.833vw;
                    font-weight: 500;
                    color: $gray;
                    animation: fadeIn 1.1s ease forwards;
                }
            }
        }

        .darkstar-img-tt {
            display: none;
        }
    }
}