@import '../../../app/utils/common/colors.scss';

.donatehelp-container {
  background-color: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.5s ease-in-out forwards;

  .donatehelp-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'M PLUS 1';

    .donatehelp-title {
      font-size: 2.5vw;
      color: $orange;
      letter-spacing: 2px;
      margin-top: 2vw;
      animation: fadeIn 0.5s ease-in-out forwards;
    }

    .donatehelp-subtitle {
      font-size: 1.5vw;
      color: $gray;
      margin-top: 1vw;
      animation: fadeIn 0.6s ease-in-out forwards;
    }

    .swipe-buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      z-index: 20;
    }

    .donatehelp-cardcontainer {
      background-color: $white;
      overflow-x: auto;
      overflow-y: hidden;
      position: relative;
      white-space: nowrap;
      max-width: 100%;
      -ms-overflow-style: none;

      scrollbar-width: none;


      ::-webkit-scrollbar {
        display: none;
      }

      .dummy-div {
        display: flex;
        transition: transform 0.5s ease-in-out;
        gap: 2.083vw;
        padding: 0 1.563vw;
        margin-bottom: 6vw;
        margin-top: 4vw;
      }

      .no-donation-available {
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 1.563vw;
        font-family: 'M PLUS 1';
        color: $orange;
      }
    }
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}


@media (max-width: 638px) {
  .donatehelp-container {
    width: 100%;

    .donatehelp-content {
      .donatehelp-title {
        font-size: 5vw;
      }

      .donatehelp-subtitle {
        font-size: 3vw;
      }

      .donatehelp-cardcontainer {
        .dummy-div {
          gap: 1vw;
        }

        .no-donation-available {
          font-size: 3vw;
        }
      }

      .swipe-buttons {
        top: 20vw;
      }
    }
  }
}