@import '../../../app/utils/common/colors.scss';

.header-container {
  width: 100%;
  display: flex;
  justify-content: center;
  animation: fadeIn 0.5s ease-in-out;

  .header {
    box-shadow: $blackShadow 0px 6px 12px -2px,
      $blackShadow 0px 3px 7px -3px;
    width: 75%;
    background-color: $white;
    height: 9%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    position: fixed;
    top: 50px;
    padding: 0 40px;
    transition: top 0.3s ease;


    &.sticky {
      width: 100%;
      background: whitesmoke;
      backdrop-filter: blur(7px);
      top: 0;
    }

    .aaradhana-logo {
      width: 14%;
      margin-left: 1.042vw;

      img {
        object-fit: contain;
        width: 80%;
      }
    }
  }

  .menu-icon {
    display: none;
    cursor: pointer;
    font-size: 24px;
  }

  .aaradhana-navbar {
    width: 85%;
    display: flex;
    justify-content: space-between;
    gap: 1vw;
    align-items: center;
    justify-content: flex-end;

    ul {
      display: flex;
      flex-direction: row;
      list-style: none;
      gap: 0.521vw;

      li {
        color: $black;
        font-weight: 500;
        font-size: 1vw;
        font-family: 'M PLUS 1', sans-serif;
        cursor: pointer;
        padding: 0.65vw;
        transition: transform 0.2s, background 0.2s;

        a {
          text-decoration: none;
          color: $black;
          all: unset;
          cursor: pointer;
        }

        &.active {
          background: $linearOrange;
          border-radius: 0.65vw;
          box-shadow: $blackShadow 0px 5px 15px;
          transform: scale(1.05);
        }

        &:hover {
          background: $linearOrange;
          border-radius: 0.65vw;
          box-shadow: $blackShadow 0px 5px 15px;
          transform: scale(1.05);
        }
      }
    }

    button {
      background: $linearOrange;
      padding: 0 1.042vw;
      font-size: 0.75vw;
      font-weight: 500;
      border: none;
      font-family: 'M PLUS 1';
      color: $white;
      border-radius: 0.65vw;
      cursor: pointer;
      margin-right: 1.042vw;
      margin-left: 1.042vw;
      box-shadow: $blackShadow 0px 5px 15px;
      transition: transform 0.2s, background 0.2s;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}


@media (max-width: 1964px) {
  .header-container {

    .header {
      top: 30px;
      height: 90px;

      .aaradhana-logo {
        img {
          width: 110px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .header-container {

    .header {
      top: 30px;
      height: 7%;

      .aaradhana-logo {
        img {
          width: 110px;
        }
      }
    }

    .aaradhana-navbar {
      gap: 10px;

      ul {
        gap: 0px;

        li {
          font-size: 15px;
          padding: 10px;
          transition: transform 0.2s, background 0.2s;

          a {}

          &.active {
            border-radius: 0.5vw;
          }

          &:hover {
            border-radius: 10px;
          }
        }
      }

      button {
        padding: 0 20px;
        font-size: 12px;
        border-radius: 10px;
      }
    }
  }

}


@media (max-width: 746px) {

  .header-container {

    .header {
      top: -1px;
      width: 100%;
      height: 7%;


      .aaradhana-logo {
        img {
          width: 100px;
          margin: 20px;
        }
      }
    }

    .menu-icon {
      display: block;
      margin: 20px;
    }

    .aaradhana-navbar {
      display: none;
      flex-direction: column;
      position: absolute;
      width: 100%;
      top: 49px;
      background: $white;
      z-index: 5;
      justify-content: center;
      text-align: center;

      &.open {
        display: flex;
      }

      ul {
        width: 100%;
        flex-direction: column;
        padding: 37px;
        justify-content: center;
        align-items: center;
        gap: 15px;

        li {
          width: 40%;
          position: relative;
          left: -10px;
          padding: 13px;
          font-size: 16px;

          a {}

          &.active {
            border-radius: 5px;
          }

          &:hover {
            border-radius: 5px;
          }
        }
      }

      button {

        width: 50%;
        position: relative;
        left: -10px;
        padding: 13px;
        border-radius: 20px;
        background: linear-gradient(to right, #363534, #2e2c2a);
        font-size: 16px;

      }
    }
  }
}


@media (max-width: 544px) {
  .header-container {

    .header {
      height: 6%;
      top: 0px;
      padding: 5px;

      .aaradhana-logo {
        img {}
      }
    }

    .menu-icon {}

    .aaradhana-navbar {
      &.open {
        display: flex;
      }

      ul {
        flex-direction: column;

        li {
          padding: 10px;
        }
      }

      button {}
    }
  }
}

html {
  scroll-behavior: smooth;
}