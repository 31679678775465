@import '../../../app/utils/common/colors.scss';

.event-container {
  display: flex;
  width: 100%;
  overflow: hidden;
  background-color: $white;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.5s ease-in-out forwards;

  .event-subcontainer {
    width: 85%;
    display: flex;
    align-items: center;


    .event-information {
      width: 35%;
      display: flex;
      flex-direction: column;
      gap: 1.042vw;
      font-family: 'M PLUS 1', sans-serif;

      .event-advantages {
        color: $orange;
        letter-spacing: 0.26vw;
        font-weight: 500;
        animation: fadeIn 0.5s ease-in-out;
      }

      .event-mngmt {
        color: $black;
        font-size: 1.823vw;
        line-height: 1.042vw;
        animation: fadeIn 0.6s ease-in-out;
      }

      .noti-icon-heading {
        display: flex;
        gap: 0.781vw;
        align-items: center;

        .notification-circle {
          background-color: $orange;
          border-radius: 50%;
          height: 2.083vw;
          width: 2.083vw;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 1.042vw;
            width: 1.042vw;
          }
        }

        .event-noti-heading {
          color: $black;
          font-size: 1.406vw;
          animation: fadeIn 0.7s ease-in-out;
        }
      }

      .event-description {
        color: $gray;
        font-size: 1.073vw;
        animation: fadeIn 0.8s ease-in-out;
      }
    }

    .event-image-lottie {
      width: 65%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .event-lottie {
        width: 42.063vw;
        z-index: 1;
        position: relative;
        animation: zoomIn 0.6s ease-in-out forwards;
      }

      .event-mobile-image {
        position: absolute;
        top: 5%;
        left: 35%;
        transform: translate(-50%, -50%);
        z-index: 2;
        animation: bounceIn 0.8s ease forwards;

        img {
          width: 20.833vw;
        }
      }
    }
  }
}


@media (max-width: 638px) {

  .event-container {

    .event-subcontainer {
      width: 100%;

      flex-direction: column;



      .event-information {
        width: 100%;
        align-items: center;

        .event-advantages {
          margin-top: 6vw;

        }

        .event-mngmt {

          font-size: 3.823vw;
          margin-top: 1vw;
          line-height: 1.042vw;
        }

        .noti-icon-heading {
          margin-top: 10px;

          .notification-circle {
            height: 4.083vw;
            width: 4.083vw;


            img {
              height: 2.042vw;
              width: 2.042vw;
            }
          }

          .event-noti-heading {
            font-size: 2.406vw;
          }
        }

        .event-description {
          font-size: 2.073vw;
          display: block;
          text-align: center;
          padding: 2vw 5vw 0vw 5vw;
        }
      }

      .event-image-lottie {
        width: 100%;

        .event-lottie {
          width: 92.063vw;
        }

        .event-mobile-image {
          position: absolute;
          top: 2%;
          left: 30%;
          transform: translate(-50%, -50%);
          z-index: 2;
          animation: bounceIn 0.8s ease forwards;

          img {
            width: 47.833vw;
          }
        }
      }
    }
  }
}