@import '../../../app/utils/common/colors.scss';

.homepage-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;

  .home-hero {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .headline-qr {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 29%;
    animation: slideIn 0.5s ease-in-out;
    z-index: 2;

    .main-headline {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .line {
        font-family: 'Montserrat Alternates', sans-serif;
        font-weight: bold;
        font-size: 3vw;
        line-height: 5vw;
        color: $white;
        text-align: center;
      }
    }

    .qr-downloadapp {
      display: flex;
      margin-top: 6%;
      flex-direction: row;
      flex-shrink: 0;
      border: 0.2rem solid $white;
      width: 70%;
      align-items: center;
      padding: 10px;
      border-radius: 30px;
      gap: 20px;
      background-color: rgba(255, 255, 255, 0.2);
      transition: transform 0.2s;

      .qr-code {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px;
        background-color: $white;
        border-radius: 30px;

        img {
          width: 90%;
          height: auto;
          object-fit: contain;
        }
      }

      .download-aaradhana {
        width: 60%;
        display: flex;
        flex-direction: column;
        font-weight: 500;
        color: $white;
        font-family: 'M PLUS 1', sans-serif;
        font-size: 1.8vw;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}

@media (max-width: 746px) {
  .homepage-container .headline-qr {
    top: 30%;

    .qr-downloadapp {
      border-radius: 14px;
      width: 60%;

      .qr-code {
        border-radius: 14px;
      }

      .download-aaradhana {
        font-size: 15px;
      }
    }
  }
}

@media (max-width: 668px) {
  .homepage-container .headline-qr {
    top: 40%;

    .qr-downloadapp {
      display: none;
    }
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-20%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}