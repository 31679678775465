@import '../../../app/utils/common/colors.scss';

.stepper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap; // Allow wrapping on smaller screens
}

.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1; // Allow flex-grow
    min-width: 80px; // Minimum width for smaller screens
    text-align: center; // Center text
}

.step-number {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    z-index: 2;
}

.step-name {
    font-size: 1vw;
}

.active .step-number {
    background-color: $gray;
    color: $white;
}

.complete .step-number {
    background-color: $orange;
    color: $white;
}

.progress-bar {
    position: absolute;
    top: 25%;
    left: 0;
    height: 4px;
    background-color: #ccc;
    z-index: 1;
    width: calc(100% - 40px);
    margin: 0 20px;
}

.progress {
    height: 100%;
    background-color: $orange;
    transition: 0.2s ease;
    z-index: 2;
}

.btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: $gray;
    color: $white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn:hover {
    background-color: $gray;
}


@media (max-width: 1352px) {
    .step-name {
        font-size: 1.5vw;
    }
}

@media (max-width: 872px) {
    .step-name {
        font-size: 2vw;
    }

    .progress-bar {
        width: calc(146% - 219px);
        margin-left: 38px;
        margin-right: 148px;
    }

    .step {
        min-height: 50px;
        min-width: 60px;
    }
}

@media (max-width: 600px) {
    .step-number {
        width: 25px;
        height: 25px;
    }

    .step-name {
        font-size: 2vw;
    }
}

@media (max-width: 400px) {
    .step-name {
        font-size: 2vw; // Larger font size for very small screens
    }
}