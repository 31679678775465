.about-pooja {
    margin-top: 1.563vw;

    .about-pooja__title {
        font-size: 1.246vw;
        font-weight: 500;
        font-family: 'M PLUS 1';
    }

    .about-pooja__content {
        display: flex;
        gap: 1.042vw;
        margin-top: 0.781vw;
        width: 100%;
        font-family: 'M PLUS 1';

        .about-pooja__image {
            img {
                width: 20.833vw;
                min-width: 18.337vw;
                height: 10.417vw;
                border-radius: 0.521vw;
                object-fit: cover;
            }
        }

        .about-pooja__description {
            width: 90%;
            font-size: 0.781vw;

            .about-pooja__read-more,
            .about-pooja__read-less {
                color: orange;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 1602px) {
    .about-pooja {
        width: 90vw;

        .about-pooja__title {
            font-size: 2.146vw;
        }

        .about-pooja__content {
            .about-pooja__description {
                width: 80vw;
                font-size: 1.4vw;

                .about-pooja__read-more,
                .about-pooja__read-less {
                    font-size: 1.4vw;
                }
            }
        }
    }
}

@media (max-width: 763px) {
    .about-pooja {
        width: 100%;
        padding: 0;

        .about-pooja__title {
            font-size: 4.146vw;
        }

        .about-pooja__content {
            flex-direction: column;
            gap: 1vw;
            margin-top: 0.781vw;

            .about-pooja__image {
                img {
                    width: 80vw;
                    height: 200px;
                    border-radius: 0.521vw;
                }
            }

            .about-pooja__description {
                width: 80vw;
                font-size: 2.5vw;

                .about-pooja__read-more,
                .about-pooja__read-less {
                    font-size: 2.5vw;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .about-pooja__title {
        font-size: 2.5vw;
    }

    .about-pooja__content {
        gap: 0.5vw;

        .about-pooja__description {
            font-size: 1.5vw;
        }
    }
}