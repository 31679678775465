@import '../../../app/utils/common/colors.scss';

.pooja-details-card {
    width: 66vw;
    display: flex;
    flex-direction: row;
    gap: 1.042vw;
    font-family: 'M PLUS 1';
    margin-bottom: 20px;

    .pooja-details-card__image {
        .carosal-styling {
            width: 27vw;

            img {
                width: 100%;
                height: 18.333vw;
                object-fit: cover;
            }
        }
    }

    .pooja-details-card__info {
        width: 100%;
        flex-wrap: wrap;

        .pooja-details-card__title {
            font-size: 1.823vw;
            font-weight: bold;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .pooja-details-card__description {
            font-size: 0.7vw;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .pooja-details-card__location,
        .pooja-details-card__date {
            display: flex;
            align-items: center;
            gap: 0.365vw;
        }

        .horizontal-line {
            width: 97%;
        }

        .pooja-details-card__location-icon img {
            width: 0.581vw;
        }

        .pooja-details-card__date-icon .calender-icon {
            width: 30px;
            margin-left: -9px;
        }

        .pooja-details-card__location-text,
        .pooja-details-card__date-text {
            font-size: 0.729vw;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .pooja-details-card__devotees {
            display: flex;
            gap: 0.521vw;

            .pooja-details-card__devotees-count,
            .pooja-details-card__devotees-text {
                font-size: 0.781vw;
            }

            .straight-line {
                border-left: 1px solid $black;
                height: 1.042vw;
            }
        }

        .pooja-details-card__timer {
            display: flex;
            justify-content: space-between;
            margin-top: 0.521vw;

            .pooja-details-card__timer_container {
                .pooja-details-card__timer-text {
                    font-size: 0.781vw;
                }

                .pooja-details-card__timer-count {
                    font-size: 1.823vw;
                }

                .pooja-details-card__timer-title {
                    font-size: 0.833vw;
                    display: flex;
                    gap: 1.8vw;
                }
            }

            .pooja-details-card__share {
                display: flex;
                align-items: center;
                gap: 0.521vw;

                .pooja-details-card__share-icon img {
                    width: 1.146vw;
                    height: 1.146vw;
                }

                .pooja-details-card__share-text {
                    font-size: 0.781vw;
                }
            }
        }

        .pooja-details-card__button {
            background-color: $orange;
            width: 100%;
            font-size: 1.25vw;
            height: 2.344vw;
            margin-top: 0.781vw;
            border-radius: 0.521vw;
            color: $white;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:hover {
                background-color: $linearOrange;
            }
        }
    }
}

@media (max-width: 1602px) {
    .pooja-details-card {
        width: 95vw;

        .pooja-details-card__title {
            font-size: 2vw;
        }

        .pooja-details-card__description {
            font-size: 1.2vw;
        }

        .pooja-details-card__location-text,
        .pooja-details-card__date-text {
            font-size: 1vw;
        }

        .pooja-details-card__devotees-count,
        .pooja-details-card__devotees-text {
            font-size: 1.1vw;
        }

        .pooja-details-card__timer-text {
            font-size: 1vw;
        }

        .pooja-details-card__timer-count {
            font-size: 2vw;
        }

        .pooja-details-card__button {
            font-size: 1.5vw;
            height: 3vw;
        }
    }
}

@media (max-width: 1385px) {
    .horizontal-line {
        margin-top: -3px;
    }
}

@media (max-width: 1153px) {
    .pooja-details-card__info .pooja-details-card__devotees {
        margin-top: -4px !important;
    }

}

@media (max-width: 923px) {

    .pooja-details-card__date,
    .pooja-details-card__location {
        gap: 0.521vw;
    }

    .pooja-details-card__date-text,
    .pooja-details-card__location-text {
        font-size: 1.5vw;
    }
}

@media (max-width: 763px) {
    .pooja-details-card {
        // width: 93vw;
        flex-direction: column;
        margin-bottom: 5px;

        .pooja-details-card__image .carosal-styling {
            width: 95vw;

            img {
                width: 100%;
                height: 200px;
            }
        }

        .pooja-details-card__info {
            width: 100%;

            .pooja-details-card__title {
                font-size: 3.823vw;
            }

            .pooja-details-card__description {
                font-size: 2.702vw;
            }

            .pooja-details-card__location,
            .pooja-details-card__date {
                gap: 5px;
                margin-top: -5px;

                .pooja-details-card__location-icon img {
                    width: 1.881vw;
                }

                .pooja-details-card__date-icon {
                    .calender-icon {
                        margin-left: 0;
                        width: 2.581vw;
                    }
                }

                .pooja-details-card__location-text {
                    font-size: 2vw;
                }

                .pooja-details-card__date-text {
                    font-size: 2vw;
                }
            }

            .pooja-details-card__devotees {
                .pooja-details-card__devotees-count {
                    font-size: 2vw;
                }

                .straight-line {
                    height: 2.042vw;
                }

                .pooja-details-card__devotees-text {
                    font-size: 2vw;
                }
            }

            .pooja-details-card__timer {
                .pooja-details-card__timer_container {
                    .pooja-details-card__timer-text {
                        font-size: 2.4vw;
                    }

                    .pooja-details-card__timer-count {
                        font-size: 4.823vw;
                    }

                    .pooja-details-card__timer-title {
                        gap: 13px;
                        font-size: 2.433vw;
                    }
                }

                .pooja-details-card__share {
                    margin-right: 10px;

                    .pooja-details-card__share-icon img {
                        width: 7.146vw;
                        height: 7.146vw;
                    }

                    .pooja-details-card__share-text {
                        font-size: 2.781vw;
                    }
                }
            }

            .pooja-details-card__button {
                font-size: 2.6vw;
                height: 6.344vw;
                width: 100%;
            }
        }
    }
}