.testimonial-container {
    width: 100%;
    font-family: 'M PLUS 1';
    margin-top: 1.042vw;

    .testimonial-heading {
        font-size: 1.146vw;
        font-weight: 500;
    }

    .testimonial-cards {
        display: flex;
        margin-top: 0.521vw;
        overflow: hidden;
        scroll-behavior: smooth;
        padding: 1vw;
        gap: 1.042vw;
        position: relative;

        .testimonial-card {
            max-width: 20vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            text-align: center;
            border-radius: 0.521vw;
            padding: 4px;
            box-shadow: 0 0.1vw 0.5vw rgba(0, 0, 0, 0.1);
            transition: transform 0.3s;

            z-index: 1;

            h3 {
                font-size: 1.2vw;
                color: #333;
            }

            p {
                font-size: 0.7vw;
                margin-top: -5px;
                color: #555;
            }

            .testimonial-author {
                font-size: 0.633vw;
                font-style: italic;
                color: #777;

                span {
                    display: block;
                    font-weight: normal;
                }
            }
        }

        .testimonial-navigation {
            width: 96%;
            display: flex;
            justify-content: space-between;
            position: absolute;
            top: 50%;
            z-index: 10;

            .testimonial-previous,
            .testimonial-next {
                width: 4vw;
                height: 2.5vw;

                &:hover {
                    color: darkorange;
                }
            }
        }
    }
}

@media (max-width: 1602px) {
    .testimonial-container {
        width: 95vw;

        .testimonial-heading {
            font-size: 2.146vw;
        }

        .testimonial-cards {
            display: flex;
            justify-content: space-between;
            margin-top: 0.521vw;
            overflow: hidden;
            scroll-behavior: smooth;
            padding: 1.042vw;
            gap: 1.042vw;
            position: relative;
            width: 93%;

            .testimonial-card {
                max-width: 27vw;
                height: 15vw;
                display: flex;
                flex-direction: column;
                text-align: center;
                border-radius: 0.521vw;
                padding: 4px;
                box-shadow: 0 0.1vw 0.5vw rgba(0, 0, 0, 0.1);
                transition: transform 0.3s;

                z-index: 1;

                h3 {
                    font-size: 1.5vw;
                    color: #333;
                }

                p {
                    font-size: 1vw;
                    margin-top: -5px;
                    color: #555;
                }

                .testimonial-author {
                    font-size: 0.833vw;
                    font-style: italic;
                    color: #777;

                    span {
                        display: block;
                        font-weight: normal;
                    }
                }
            }

            .testimonial-navigation {
                width: 96%;
                display: flex;
                justify-content: space-between;
                position: absolute;
                top: 50%;
                z-index: 10;

                .testimonial-previous,
                .testimonial-next {
                    width: 4vw;
                    height: 2.5vw;

                    &:hover {
                        color: darkorange;
                    }
                }
            }
        }
    }
}

@media (max-width: 763px) {
    .testimonial-container {
        margin-top: 2.342vw;
        width: 100%;

        .testimonial-heading {
            font-size: 4vw;
        }

        .testimonial-cards {
            display: flex;
            margin-top: 2.521vw;
            width: 80vw;

            .testimonial-card {
                min-width: 78vw;
                height: 25vw;

                h3 {
                    font-size: 3vw;
                }

                p {
                    font-size: 1.9vw;
                }

                .testimonial-author {
                    font-size: 1.833vw;
                    margin-top: 5px;
                }
            }

            .testimonial-navigation {
                top: 40%;

                .testimonial-previous,
                .testimonial-next {
                    width: 20px;
                    height: 40px;

                    &:hover {
                        color: darkorange;
                    }
                }
            }
        }
    }
}