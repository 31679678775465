.puja-packages {
    width: 100%;
    margin-top: 1.563vw;
    height: auto;

    .puja-packages__title {
        font-size: 1.146vw;
        font-weight: 500;
        font-family: 'M PLUS 1';
    }

    .puja-packages__description {
        font-size: 0.937vw;
        font-weight: 400;
        font-family: 'M PLUS 1';
    }

    .puja-package {
        display: flex;
        gap: 1.042vw;
        flex-wrap: wrap;

        .puja-packages__item {
            margin-top: 1.042vw;
            border: 0.1px solid rgb(172, 166, 166);
            border-radius: 0.521vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 20vw;
            font-family: 'M PLUS 1';
            background: linear-gradient(45deg, #ff7e00, #ffcc00);
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

            .puja-packages__item-name,
            .puja-packages__item-price {
                font-size: 2vw;
                margin-top: 0.625vw;
                font-weight: 500;
            }

            .puja-packages__item-details {
                margin-top: 0.5vw;
            }

            .puja-packages__divider {
                width: 90%;
                border: 1px solid rgb(168, 160, 160);
            }

            .puja-packages__item-info {
                width: 90%;

                .puja_package_points {
                    display: flex;
                    flex-direction: column;
                    gap: 1vw;
                    height: 15vw;
                    overflow-y: scroll;

                    &::-webkit-scrollbar {
                        width: 0.5vw;
                    }

                    &::-webkit-scrollbar-track {
                        background: #f1f1f1;
                        border-radius: 10px;
                    }

                    &::-webkit-scrollbar-thumb {
                        background: #888;
                        border-radius: 10px;
                    }

                    &::-webkit-scrollbar-thumb:hover {
                        background: #555;
                    }

                    .pooja-point {
                        margin-top: 0.5vw;
                        display: flex;
                        gap: 1vw;
                    }
                }
            }

            .puja-packages__confirm {
                background-color: #000000;
                width: 90%;
                height: 4.167vw;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 1.042vw 0;
                border-radius: 0.521vw;
                color: white;
                cursor: pointer;
            }
        }
    }
}

@media (max-width: 763px) {
    .puja-packages {
        .puja-packages__title {
            font-size: 4.146vw;
        }

        .puja-packages__description {
            font-size: 2.5vw;
        }

        .puja-package {
            .puja-packages__item {
                width: 70%;
                height: auto;

                .puja-packages__item-info {
                    .puja_package_points {
                        padding: 4vw;
                        height: 40vw;
                        font-size: 2.4vw;
                    }
                }

                .puja-packages__item-name,
                .puja-packages__item-price {
                    font-size: 4.5vw;
                }

                .puja-packages__confirm {
                    height: 5vw;
                    font-size: 3.2vw;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .puja-packages__title {
        font-size: 2.5vw;
    }

    .puja-packages__description {
        font-size: 1.75vw;
    }

    .puja-package {
        .puja-packages__item {

            .puja-packages__item-name,
            .puja-packages__item-price {
                font-size: 3vw;
            }

            .puja_package_points {
                height: 25vw;
            }

            .puja-packages__confirm {
                height: 6vw;
            }
        }
    }
}