@import '../../../app/utils/common/colors.scss';

.namaste-container {
  width: 100%;
  background-color: $white;
  position: relative;

  .fade-temple {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    img {
      width: 67.188vw;
      object-fit: cover;
    }

    .namaste-title {
      position: absolute;
      top: 48%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      text-align: center;
      color: $orange;
      font-family: 'Montserrat Alternates', sans-serif;
      font-size: 2.292vw;
      font-weight: 550;
      opacity: 0;
      animation: fadeIn 0.6s forwards 0.3s;
    }

    .namaste-info {
      position: absolute;
      top: 63%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      text-align: center;
      margin-top: 2.865vw;
      flex-direction: column;
      color: $gray;
      font-family: 'M PLUS 1', sans-serif;
      font-weight: 550;
      font-size: 1.13vw;
      opacity: 0;
      animation: fadeIn 0.6s forwards 0.6s;
    }
  }

  .malefemale-temple {
    display: flex;
    margin-top: 33.708vw;
    align-items: flex-end;
    justify-content: space-around;

    .male-female-image {
      display: flex;

      .male img {
        width: 22.396vw;
      }

      .female {
        margin-left: -5.208vw;

        img {
          width: 22.917vw;
        }
      }
    }

    .hindu-temple {
      margin-bottom: 7.813vw;

      img {
        width: 42.708vw;
      }
    }
  }
}

@media (max-width: 638px) {
  .namaste-container {
    width: 100%;

    .fade-temple {
      width: 100%;

      .namaste-title {
        width: 90%;
        font-size: 3vw !important;
      }

      .namaste-info {
        width: 100%;
        font-size: 2vw !important;
      }
    }

    .malefemale-temple {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column !important;

      .male-female-image {
        .male {
          img {
            display: none;
          }
        }

        .female {
          display: none;

          img {
            width: 42.396vw;
          }
        }
      }

      .hindu-temple {
        img {
          width: 62.396vw;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}