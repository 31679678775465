@import '../../../app/utils/common/colors.scss';

.CommunityDonation-container {
  width: 100%;
  overflow: hidden;

  .CommunityDonation-subcontainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    animation: fadeIn 0.5s ease-in-out forwards;

    .lottie-img {
      width: 55%;

      .lotti-circle {
        margin-top: 4.688vw;
        position: relative;

        .mobile-image {
          position: absolute;
          top: 54%;
          left: 55%;
          transform: translate(-50%, -50%);
          animation: slideInFromBottom 0.7s ease-in-out;

          img {
            width: 25.833vw;
            animation: fadeIn 0.7s ease-in-out forwards;
          }
        }
      }
    }

    .donationlogo-content {
      .comm-donation-logo {
        width: 46.833vw;
        animation: fadeIn 0.7s ease-in-out;
      }

      .features-communitydonation {
        width: 65%;
        font-family: 'M PLUS 1';

        .feature-title {
          color: $orange;
          font-weight: 400;
          letter-spacing: 0.156vw;
          font-size: 1.042vw;
          animation: fadeIn 0.7s ease-in-out;
        }

        .com-donation {
          margin-bottom: 0.521vw;
          font-size: 1.563vw;
          font-weight: bold;
          line-height: 2.76vw;
          animation: fadeIn 0.7s ease-in-out;
        }

        .starlogo-title-description {
          margin-bottom: 0.781vw;

          .starlogo-title {
            display: flex;
            align-items: center;
            gap: 0.521vw;
            font-size: large;
            font-weight: 500;
            animation: fadeIn 0.7s ease-in-out;

            img {
              width: 1.25vw;
              height: 1.25vw;
            }
          }

          .starlogo-description {
            font-size: 0.781vw;
            color: $gray;
            animation: fadeIn 0.7s ease-in-out;
          }
        }
      }
    }
  }
}

@media (max-width: 635px) {
  .CommunityDonation-container {
    width: 100%;

    .CommunityDonation-subcontainer {
      width: 100%;
      display: flex;
      flex-direction: column-reverse;

      .lottie-img {
        width: 100%;

        .lotti-circle {
          .mobile-image {
            img {
              width: 49.625vw;
              animation: fadeIn 0.7s ease-in-out;
            }
          }
        }
      }

      .donationlogo-content {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;

        .comm-donation-logo {
          width: 70.063vw;
        }

        .features-communitydonation {
          margin-top: 5.042vw;
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;

          .feature-title {
            font-size: 3.781vw;
          }

          .com-donation {
            display: none;
          }

          .starlogo-title-description {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-top: 1.521vw;

            .starlogo-title {
              font-size: 2.625vw;

              img {
                display: none;
              }
            }

            .starlogo-description {
              width: 80%;
              display: flex;
              justify-content: center;
              text-align: center;
              font-size: 1.9vw;
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}