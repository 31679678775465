@import '../../../app/utils/common/colors.scss';

.pooja-details {
    margin-top: 8.417vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: 'M PLUS 1';

    .pooja-details-steps {
        width: 100%;
        display: flex;
        justify-content: center;

        .pooja-details-step {
            width: 80%;

            .multi-tabs {
                width: 100%;
                display: flex;
            }

            .custom-stepper .step-line {}
        }
    }

    .Connector-d7-0-2-35 {
        // display: block;
        // border-color: rgb(189, 189, 189);
        // border-top-style: solid;
        border-top-width: 5px;
    }

    .Connector-d5-0-2-29 {
        border-top-width: 5px;
    }

    .Connector-d3-0-2-23 {
        border-top-width: 5px;
    }

    .Connector-d1-0-2-17 {
        border-top-width: 5px;
    }

    // .LabelContainer-d6-1-2-30 {
    //     font-size: 10px;

    // }

    // .LabelContainer-d4-1-2-24 {
    //     font-size: 10px;
    // }

    // .LabelContainer-d2-1-2-18 {
    //     font-size: 10px;
    // }

    // .LabelContainer-d0-1-2-10 {
    //     font-size: 10px;
    // }

    @media (max-width: 1602px) {
        .pooja-details-steps {
            margin-top: 25px;
            height: 100px;

            .ant-steps {
                flex-wrap: wrap;

                &-item-title {
                    font-size: 10px !important;
                    color: #333;
                    font-weight: 500;
                    line-height: 1.667vw;
                }
            }
        }

        .pooja-details-card-main {
            margin-top: 35px;
        }


    }

    @media (max-width: 638px) {
        margin-top: 15.674vw;

        .Label-d9-0-2-37 {
            display: block;
            margin-top: 0px;
            text-align: center;
            font-weight: 500;
        }


        .StepperContainer-0-2-1 {
            display: flex;
            margin-left: -25px;
            width: 100%;
            margin-top: -20px;
            justify-content: center;
            font-family: Arial, Helvetica, sans-serif;
            flex-direction: row;
        }


    }
}

@media (max-width: 406px) {
    .pooja-details {
        margin-top: 13.674vw;
    }
}