@import '../../../app/utils/common/colors.scss';

.footer {
  width: 100%;
  background-color: $black;
  background-image: url(../../../assets/images/Footerimages/Vector.png);
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  animation: fadeIn 0.6s ease forwards;

  a {
    text-decoration: none;
    color: $white;
  }

  .footer-info {
    width: 80%;
    margin: 3.125vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    padding: 2.083vw 0;

    .footer-one,
    .footer-column {
      flex: 1 1 20%;
      display: flex;
      flex-direction: column;
      // align-items: center;
      color: $white;
      gap: 0.321vw;
      padding: 0.521vw;
      opacity: 0;
      animation: fadeIn 1s ease forwards;

      &:nth-child(1) {
        animation-delay: 0.2s;
      }

      &:nth-child(2) {
        animation-delay: 0.4s;
      }

      &:nth-child(3) {
        animation-delay: 0.6s;
      }

      h2 {
        font-size: 1.142vw;
        margin-bottom: 0.521vw;
        font-family: 'M PLUS 1';
      }

      a {
        font-size: 1.081vw;
        font-family: 'M PLUS 1';
      }

      .logo-image img {
        width: 70%;
      }

      .footer-socialmedia-logos {
        display: flex;
        // justify-content: space-between;
        gap: 0.521vw;

        .social-logo {
          width: 3.125vw;
          height: 3.125vw;
          border-radius: 50%;
          border: 0.052vw solid $white;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 10%;
          cursor: pointer;
          transition: transform 0.3s ease;

          img {
            width: 55%;
            cursor: pointer;
          }

          &:hover {
            background-color: $black;
            transform: scale(1.1);
          }
        }
      }
    }

    .contact-info {
      flex: 1 1 30%;
      display: flex;
      flex-direction: column;

      .contact {
        display: flex;
        align-items: center;

        img {
          width: 5%;
        }

        a {
          margin-left: 1.563vw;
        }
      }
    }
  }



  .final-footer {
    display: flex;
    align-items: center;
    gap: 0.521vw;
    justify-content: center;
    background: $linearOrange;
    width: 100%;
    height: 5vh;
    font-family: 'M PLUS 1';
    color: $white;
    font-size: 0.833vw;
    z-index: 3;
    animation: fadeIn 0.8s ease forwards;
  }

  .return-to-home {
    background: $linearOrange;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 10;
    width: 3.646vw;
    height: 3.646vw;
    border-radius: 50%;
    position: absolute;
    bottom: 9.51vw;
    right: 3.385vw;

    img {
      animation: bounce 1s infinite;
      width: 1.302vw;
      height: auto;
    }
  }
}

@media (max-width: 1255px) {
  .footer {
    background-image: none;
  }
}

@media (max-width:640px) {
  .footer {
    background-image: none;

    .footer-info {
      flex-direction: column;
      justify-content: space-between;
      padding: 0px;
      width: 90%;
      margin-top: 10px;

      .footer-one,
      .footer-column {
        display: flex;
        align-items: center;

        .footer-socialmedia-logos {
          gap: 3.521vw;

          .social-logo {
            width: 7.125vw;
            height: 7.125vw;
          }
        }

        a {
          font-size: 3vw;
        }

        .logo-image {
          display: flex;
          justify-content: center;
        }
      }

      .footer-column,
      .contact-info {
        width: 100%;
        justify-content: space-around;

        h2 {
          font-size: 3.25vw;
        }

        a {
          font-size: 2.45vw;
        }
      }

      .contact-info {
        .contact {
          margin-bottom: 0.26vw;

          img {
            width: 1.042vw;
            height: 1.042vw;
          }
        }
      }
    }


    .final-footer {
      font-size: 2.729vw;
    }

    .return-to-home {
      width: 12.125vw;
      height: 12.125vw;
      bottom: 19.51vw;
      right: 9.385vw;

      img {
        animation: bounce 1s infinite;
        width: 4.502vw;
        height: auto;
      }
    }
  }


}