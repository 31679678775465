@import '../../../app/utils/common/colors.scss';

.instagram-feeds-container {
  width: 100%;
  padding: 1.042vw;
  background-color: $white;
  display: flex;
  flex-direction: column;
  font-family: 'M PLUS 1';
  animation: fadeIn 0.6s ease forwards;

  .instagram-feeds-subcontainer {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;

    .instagram-animation-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.042vw;
      width: 67%;
      position: relative;

      .animation-circle {
        z-index: 1;
        width: 57.292vw;
        animation: pulse 2s infinite;
      }

      .instagram-imageone-wrapper,
      .instagram-imagetwo-wrapper {
        z-index: 2;
        position: absolute;
        animation: slideIn 0.8s ease forwards;

        img {
          width: 28.125vw;
        }
      }

      .instagram-imageone-wrapper {
        top: 1px;
        animation-delay: 0.2s;
      }

      .instagram-imagetwo-wrapper {
        top: 7.813vw;
        right: 0.156vw;
        right: 0.156vw;
        animation-delay: 0.4s;
      }
    }

    .instagram-info-section {
      margin-bottom: 1.042vw;
      width: 33%;
      display: flex;
      flex-direction: column;
      animation: fadeInUp 0.6s ease forwards;

      .instagram-title {
        font-size: 0.938vw;
        color: $orange;
        margin-bottom: 0.521vw;
        letter-spacing: 0.156vw;
        animation: fadeIn 0.5s ease forwards;
      }

      .instagram-description-wrapper {
        margin-bottom: 0.521vw;
        display: flex;
        align-items: center;
        gap: 0.521vw;

        .instagram-icon-wrapper {
          background-color: $orange;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.083vw;
          height: 2.083vw;
          animation: bounce 1s infinite;
        }

        .instagram-subtitle {
          font-size: 1.25vw;
          color: $black;
          font-weight: bold;
          width: 11.927vw;
          margin-bottom: 0.26vw;
          animation: fadeIn 0.6s ease forwards;
        }


      }

      .instagram-details {
        font-size: 1.229vw;
        // font-size: 200px;
        color: $gray;
        line-height: 1.5;
        animation: fadeIn 0.7s ease forwards;
      }
    }

    .instagram-footer-image {
      width: 100%;
      // max-width: 600px;
      margin: 0 auto;
      border-radius: 0.521vw;
      animation: fadeIn 0.8s ease forwards;
    }
  }

  .aaradhana-namaste-image {
    margin-top: 4.208vw;

    img {
      position: relative;
      width: 50.521vw;
      // height: 88%;
      right: -25.458vw;
      animation: slideInRight 0.7s ease forwards;
    }
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translateX(30px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes pulse {

  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

@media (max-width: 638px) {
  .instagram-feeds-container {
    width: 100%;

    .instagram-feeds-subcontainer {
      width: 100%;
      flex-direction: column-reverse;

      .instagram-animation-wrapper {
        width: 100%;

        .animation-circle {
          width: 88.292vw;

        }

        .instagram-imageone-wrapper,
        .instagram-imagetwo-wrapper {


          img {
            width: 47.833vw;
          }
        }

        .instagram-imageone-wrapper {
          top: 1.104vw;
          animation-delay: 0.2s;
        }

        .instagram-imagetwo-wrapper {
          top: 7.813vw;
          right: 15.208vw;

        }
      }

      .instagram-info-section {
        margin-top: 6.042vw;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;

        .instagram-title {
          font-size: 2.938vw;
        }

        .instagram-description-wrapper {
          margin-bottom: 0.521vw;
          display: flex;
          justify-content: center;
          align-items: center;


          .instagram-icon-wrapper {
            display: none;

          }

          .instagram-subtitle {
            font-size: 3.25vw;

            width: 100%;

          }


        }

        .instagram-details {
          font-size: 2.229vw;
          width: 90%;
          display: flex;
          text-align: center;
          justify-content: center;
        }
      }

      .instagram-footer-image {
        width: 100%;
        margin: 0 auto;
        border-radius: 0.521vw;
        animation: fadeIn 0.8s ease forwards;
      }
    }

    .aaradhana-namaste-image {
      margin-top: -5.208vw;

      img {
        position: relative;
        width: 70.521vw;
        right: -16.458vw;
        animation: slideInRight 0.7s ease forwards;
      }
    }
  }

}