.faqs-container {
    margin-top: 1.563vw;

    .faqs-title {
        font-size: 1.146vw;
        font-weight: 500;
        font-family: 'M PLUS 1';
        margin-bottom: 0.625vw;
    }

    .faq-item {
        margin-bottom: 1.042vw;

        .faq-question {
            cursor: pointer;
            font-size: 1.146vw;
            font-weight: 600;
            color: #007bff;
            border-left: 0.1vw solid orange;
            padding-left: 0.521vw;

            &:hover {
                text-decoration: underline;
            }
        }

        .faq-answer {
            margin-left: 1.25vw;
            margin-top: 0.5vw;
            font-size: 0.729vw;
            color: #555;
        }
    }
}

@media (max-width: 1602px) {
    .faqs-container {

        .faqs-title {
            font-size: 2.146vw;
        }

        .faq-item {


            .faq-question {
                cursor: pointer;
                font-size: 1.746vw;

            }

            .faq-answer {
                font-size: 1vw;

            }
        }
    }
}

@media (max-width: 763px) {
    .faqs-container {
        .faqs-title {
            font-size: 4.146vw;
            margin-bottom: 1vw;
        }

        .faq-item {
            margin-bottom: 1.5vw;

            .faq-question {
                font-size: 3.242vw;
                border-left: 0.2vw solid orange;
                padding-left: 1vw;
            }

            .faq-answer {
                margin-left: 2vw;
                margin-top: 0.75vw;
                font-size: 2.5vw;
            }
        }
    }
}

@media (max-width: 480px) {
    .faqs-title {
        font-size: 2.5vw;
    }

    .faq-item {
        .faq-question {
            font-size: 2.5vw;
            padding-left: 1.5vw;
        }

        .faq-answer {
            font-size: 2vw;
            margin-left: 2.5vw;
        }
    }
}