@import '../../../app/utils/common/colors.scss';

.pooja-card {
    width: 22.917vw;
    border: 2px solid $orange;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: $white;
    box-shadow: 0px 5px 15px$blackShadow;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: scaleIn 0.5s ease forwards;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 10px 20px $blackShadow;
    }

    .card-inside-image {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 10px 10px 0px 10px;

        img {
            object-fit: cover;
            border-radius: 1.042vw;
            width: 20.313vw;
            height: 14.115vw;
        }
    }

    .card-inside-info {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        .card-title {
            font-size: 1.302vw;
            font-weight: bold;
            margin: 0.521vw 0;
            width: 95%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            animation: fadeIn 0.5s ease-in-out forwards;
        }

        .card-subtitle,
        .card-location {
            font-size: 1.094vw;
            color: $gray;
            font-weight: 500;
            width: 15.625vw;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            animation: fadeIn 0.5s ease-in-out forwards;
        }
    }

    .pooja-date {
        padding: 1.042vw;
        margin-bottom: 0.885vw;
        color: $orange;
        font-size: 1.406vw;
        text-decoration: underline;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        animation: fadeIn 0.5s ease-in-out forwards;
    }

    .book-puja-button {
        width: 40%;
        height: 11%;
        border-radius: 10px;
        background-image: $linearOrange;
        color: $white;
        font-family: 'M PLUS 1';
        position: absolute;
        bottom: -1.51vw;
        left: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translateX(-50%);
        font-size: 0.99vw;
        font-weight: 500;
        cursor: pointer;
        box-shadow: $blackShadow 0px 10px 36px, $blackShadow 0px 0px 1px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: $linearOrange;
        }
    }
}