.css-1wxkzlj-MuiTabs-flexContainer {
    background-color: orange;
    border-radius: 5px;
    display: flex;
    align-items: baseline;
    justify-content: center;
}

.css-19kzrtu {
    padding: 24px;
    width: 66vw;
    height: auto;
}

.css-1usuzwp-MuiButtonBase-root-MuiTab-root {
    font-size: 1vw;
    font-weight: 700 !important;
    font-family: 'M PLUS 1';

    @media (max-width: 763px) {
        font-size: 2vw !important;
    }
}

@media (max-width: 1602px) {
    .tabs-pooja-details {
        width: 95vw;
    }
}

@media (max-width: 763px) {
    .tabs-pooja-details {
        width: 95vw;
    }
}