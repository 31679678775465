@import '../../../app/utils/common/colors.scss';

.features-container {
  width: 100%;
  animation: fadeIn 0.5s ease-in-out forwards;

  .digitalplatform-marque {
    width: 104%;
    position: relative;
    left: -1.042vw;
    height: 4.688vw;
    background-color: $black;
    transform: rotate(354deg);
    color: $white;
    transform-origin: left;
    z-index: 100;
    display: flex;
    align-items: center;
    font-size: 1.823vw;
    font-weight: bold;
    font-family: 'Montserrat Alternates';
    animation: slideInFromLeft 0.7s ease-in-out;
  }

  .songsvideos-marque {
    height: 4.688vw;
    background-color: $orange;
    display: flex;
    color: $white;
    font-size: 1.563vw;
    font-weight: bold;
    align-items: center;
    font-family: 'Montserrat Alternates';
    animation: slideInFromRight 0.7s ease-in-out;
  }

  .test-container {
    clip-path: polygon(100% 0, 100% 86%, 0 100%, 0 0);
    background-image: $linearOrange;
    width: 100%;

    .features-screenshot {
      font-size: 1.963vw;
      width: 90%;
      color: $white;
      font-family: 'Montserrat Alternates';
      font-weight: 550;
      line-height: 4.167vw;
      display: flex;
      justify-content: space-around;
      animation: fadeInUp 0.7s ease-in-out;

      .feature-list {
        width: 30%;
        margin-top: 7.813vw;
        animation: fadeInUp 0.7s ease-in-out;
        font-size: 1.5vw;

        .consume-media {
          line-height: 2.344vw;
        }
      }

      .iphone-rings {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        margin-top: 7.813vw;

        .FeatureCircle {
          width: 41.667vw;
          z-index: 1;
        }

        .iphoneone,
        .iphonetwo,
        .iphonethree {
          position: absolute;
          z-index: 4;
          left: 4.854vw;
          animation: bounceIn 0.7s ease-in-out;

          img {
            width: 26.313vw;
          }
        }

        .iphoneone {
          z-index: 5;
        }

        .iphonetwo {
          left: 9.542vw;
          top: 1.042vw;
          z-index: 4;
        }

        .iphonethree {
          left: 14.708vw;
          top: 1.823vw;
          z-index: 3;
          bottom: 0;
        }
      }
    }

    .aaradhanafade-image {
      display: flex;
      justify-content: flex-end;
      animation: fadeIn 0.7s ease-in-out;
      margin-right: 1.563vw;
      margin-bottom: 5.208vw;
      rotate: 1deg;
      padding: 5.208vw;

      img {
        width: 45%;
      }
    }
  }
}

@media (max-width: 639px) {
  .features-container {
    .digitalplatform-marque {
      height: 5vh;
      font-size: 3vw;
    }

    .songsvideos-marque {
      height: 5vh;
      font-size: 3vw;
    }

    .test-container {
      width: 100%;

      .features-screenshot {
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .feature-list {
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 20px;

          ul {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            list-style: none;

            li {
              font-size: 3.781vw;
              line-height: 8vw;
            }
          }

          .consume-media {
            line-height: 4vw;
          }
        }

        .iphone-rings {
          width: 100%;
          position: relative;
          z-index: 1;
          display: flex;
          align-items: center;
          margin-bottom: 90px;

          .FeatureCircle {
            width: 900px;
            z-index: 1;
          }

          .iphoneone,
          .iphonetwo,
          .iphonethree {
            img {
              width: 59.313vw;
            }
          }

          .iphoneone {
            z-index: 5;
          }

          .iphonetwo {
            left: 20.542vw;
            top: 14px;
            z-index: 4;
          }

          .iphonethree {
            left: 35.708vw;
            top: 35px;
            z-index: 3;
            bottom: 0;
          }
        }
      }

      .aaradhanafade-image {
        display: none;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounceIn {
  from {
    opacity: 0;
    transform: scale(0.3);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}