@import '../../../app/utils/common/colors.scss';

.download-app {
    width: 100%;
    margin-bottom: 7.813vw;
    font-family: 'M PLUS 1';
    background-color: $white;
    animation: fadeIn 0.6s ease forwards;

    &__container {
        width: 60%;
        background-color: $downloadApptemp;
        margin: 0 auto;
        display: flex;
        padding: 0 2.604vw;
        justify-content: space-around;
        align-items: center;
        animation: slideInUp 0.8s ease forwards;
    }

    &__header {
        position: relative;

        .bottom-circle {
            position: absolute;
            top: 7.406vw;
            left: -2.604vw;
            z-index: 1;
        }
    }

    &__title {
        font-size: 2.813vw;
        font-weight: bold;
        margin-bottom: 0.521vw;
        color: $white;
        animation: fadeIn 0.7s ease forwards;
    }

    &__subtitle {
        font-size: 0.99vw;
        color: $white;
        margin-bottom: 1.042vw;
        width: 60%;
        animation: fadeIn 0.8s ease forwards;
    }

    &__features {
        display: flex;
        justify-content: flex-start;
        gap: 1.042vw;
        cursor: pointer;
        font-size: 0.885vw;
        animation: fadeIn 0.9s ease forwards;

        .download-app__feature {
            display: flex;
            align-items: center;
            gap: 0.521vw;
            justify-content: center;
            color: $white;
            width: 9.375vw;
            height: 2.604vw;
            z-index: 100;
            background-image: $linearOrange;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.05);
            }

            img {
                width: 1.042vw;
                height: 1.25vw;
            }
        }
    }

    .download-app__images {
        display: flex;
        position: relative;

        .download-app__imageone,
        .download-app__imagetwo {
            width: 20.833vw;
            height: auto;
            z-index: 2;
            animation: fadeIn 1s ease forwards;
        }

        .download-app__imageone {
            margin-right: 6.354vw;
            animation: fadeInLeft 1s ease forwards;
        }

        .download-app__imagetwo {
            position: absolute;
            top: 3.281vw;
            right: 0;
            animation: fadeInRight 1s ease forwards;
        }

        .upper-circle {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 11.979vw;
        }

        .DownloadAppTemplate-shadow {
            z-index: 15;
            position: absolute;
            height: 15.625vw;
            width: 15.625vw;
            top: -6.198vw;
            left: 19.219vw;
            background: $linearOrange;
            filter: blur(8.5em);
        }

        .lite-star-two-dat {
            z-index: 15;
            position: absolute;
            height: 2.604vw;
            width: 2.604vw;
            top: -6.198vw;
            left: 34.844vw;
            transform: rotate(27deg);
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes slideInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(20px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@media (max-width: 635px) {
    .download-app {
        width: 100%;

        &__container {
            width: 83%;
            padding: 30px 2.604vw;
            text-align: center;
            align-items: center;
            justify-content: center;
        }

        &__header {
            .bottom-circle {
                display: none;
            }
        }

        &__title {
            font-size: 3.813vw;
        }

        &__subtitle {
            font-size: 2.3vw;
            width: 100%;
        }

        &__features {
            justify-content: center;
            gap: 3.042vw;

            .download-app__feature {
                text-align: center;
                margin-top: 10px;
                border-radius: 5px;
                width: 17.375vw;
                height: 5.604vw;
                font-size: 1.5vw;

                &:hover {
                    transform: scale(1.05);
                }

                img {
                    width: 1.542vw;
                    height: 1.825vw;
                }
            }
        }

        .download-app__images {
            display: none;

            .download-app__imageone,
            .download-app__imagetwo,
            .upper-circle,
            .lite-star-two-dat {
                display: none;
            }
        }
    }
}