@import '../../../app/utils/common/colors.scss';

.experience-container {
  width: 100%;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.5s ease-in-out forwards;

  .exp-sub-container {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'M PLUS 1';
    animation: slideInFromBottom 0.7s ease-in-out;

    .our-experience {
      padding: 2.083vw;

      .experience-headline,
      .our-slogan,
      .star-description,
      .our-description {
        animation: fadeIn 0.7s ease-in-out;
      }

      .experience-headline {
        color: $orange;
        font-weight: 450;
        letter-spacing: 0.26vw;
        font-size: medium;
      }

      .our-slogan {
        font-weight: bold;
        font-size: 2.448vw;
        width: 80%;
      }

      .star-description {
        margin-top: 1.302vw;

        .star-title {
          display: flex;
          align-items: center;
          gap: 0.521vw;

          img {
            width: 1.042vw;
            height: 1.042vw;
            animation: bounceIn 0.7s ease-in-out;
          }

          .exp-name {
            font-size: 1.781vw;
            font-weight: 500;
          }
        }

        .our-description {
          width: 60%;
          color: $gray;
        }
      }

      .sliding-advertise {
        position: relative;
        left: 9.063vw;

        .marq-slideone,
        .marq-slidetwo,
        .marq-slidethree {
          position: relative;
          width: 60%;
          height: 4.167vw;
          left: 100px;
          display: flex;
          font-size: 2vw;
          align-items: center;
          font-weight: 600;
          color: $white;
          border-radius: 0px 0.521vw 0.521vw 0px;
          animation: slideInFromLeft 0.7s ease-in-out;
        }

        .marq-slideone {
          z-index: 3;
          background-color: $black;
          transform: rotate(330deg);
        }

        .marq-slidetwo {
          z-index: 2;
          top: -2.923vw;
          width: 40%;
          left: 16.698vw;
          transform: rotate(-52deg);
          background-image: $linearOrange;
          clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
        }

        .marq-slidethree {
          background-image: $linearOrange;
          width: 32%;
          left: 20.599vw;
          top: 10px;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
          animation: slideInFromBottom 0.7s ease-in-out;
        }
      }
    }

    .maharaj-image {
      img {
        width: 17.708vw;
        padding: 2.083vw;
        z-index: 3;
        animation: fadeIn 0.7s ease-in-out;
      }
    }
  }
}

@media (max-width: 1493px) {
  .sliding-advertise {
    display: none;
  }
}

@media (max-width: 633px) {
  .experience-container {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.521vw;

    .exp-sub-container {
      width: 100%;
      flex-direction: column;

      .our-experience {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .experience-headline {
          width: 100%;
          font-size: 3.781vw;
          text-align: center;
        }

        .our-slogan {
          font-size: 3.5vw !important;
          width: 100%;
          text-align: center;
        }

        .star-description {
          width: 100%;
          text-align: center;
          font-size: 2.229vw;

          .star-title {
            width: 100%;
            text-align: center;

            img {
              display: none;
            }

            .exp-name {
              margin-top: -1.5vw;
              font-size: 3.625vw;
              width: 100%;
              text-align: center;
            }
          }

          .our-description {
            width: 100%;
            text-align: center;
          }
        }
      }

      .maharaj-image {
        img {
          width: 42.396vw;
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideInFromBottom {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes bounceIn {
  from {
    opacity: 0;
    transform: scale(0.3);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}