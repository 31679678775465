$circle-diameter: 300px;
$profile-image-size: 70px;
$center-image-size: 80px;
@import '../../../app/utils/common/colors.scss';

.main-container {
    position: relative;
    width: $circle-diameter;
    height: $circle-diameter;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 4px dotted $orange;
    border-radius: 50%;
}

.circle {

    position: absolute;
    width: 100%;
    height: 100%;
    animation: rotate 12s linear infinite;
    display: flex;
    align-items: center;
    justify-content: center;

    .profile-image {
        position: absolute;
        width: $profile-image-size;
        height: $profile-image-size;
        background-size: cover;
        background-position: center;
        border-radius: 50%;
        box-shadow: 0 4px 8px $blackShadow;
        transition: transform 0.3s ease, box-shadow 0.3s ease;
        animation: revRotate 12s linear infinite;
        transform: rotate(180deg);

        &:hover {
            transform: scale(1.1);
            box-shadow: 0 6px 12px $blackShadow;
        }
    }
}

.center-image {
    width: $center-image-size;
    height: $center-image-size;
    position: relative;
    z-index: 1;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    transition: transform 0.5s ease;

    &:hover {
        transform: scale(1.05);
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes revRotate {
    0% {
        transform: rotate(360deg);

    }

    100% {

        transform: rotate(0deg);
    }
}