.puja-benefits {
    .puja-benefits__title {
        font-size: 1.146vw;
        font-weight: 500;
        font-family: 'M PLUS 1';
    }

    .puja-benefits__list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-top: 0.781vw;
        font-family: 'M PLUS 1';

        .puja-benefits__item {
            display: flex;
            align-items: center;
            text-align: center;
            gap: 0.521vw;

            .puja-benefits__item-description {
                font-size: 1.042vw;
                font-weight: 500;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .poojadetails-arrow {
                height: 22px;
                width: 22px;
            }
        }
    }

    .puja-benefits__item-details {
        margin-left: 1.823vw;
        font-family: 'M PLUS 1';
        font-size: 1vw;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 1602px) {
    .puja-benefits {
        width: 95vw;

        .puja-benefits__title {
            font-size: 2.146vw;
        }

        .puja-benefits__list {

            .puja-benefits__item {

                .puja-benefits__item-description {
                    font-size: 1.742vw;
                }

                .poojadetails-arrow {
                    height: 20px;
                    width: 20px;
                }
            }
        }

        .puja-benefits__item-details {
            width: 90%;
            font-size: 1.4vw;

        }
    }
}


@media (max-width: 763px) {
    .puja-benefits {
        .puja-benefits__title {
            margin-top: -10px;
            font-size: 4.146vw;
        }

        .puja-benefits__list {
            .puja-benefits__item {
                gap: 5px;

                .puja-benefits__item-description {
                    font-size: 3.142vw;
                }

                .poojadetails-arrow {
                    width: 17px;
                }
            }
        }

        .puja-benefits__item-details {
            font-size: 2.4vw;
        }
    }
}