@import '../../../app/utils/common/colors.scss';

.bookpuja-container {
  background-color: $white;
  width: 100%;
  display: flex;
  flex-direction: column;
  animation: fadeIn 0.5s ease-in-out forwards;

  .pujarti-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'M PLUS 1';

    .puja-aarti {
      font-size: 1.198vw;
      letter-spacing: 5px;
      color: $orange;
      animation: fadeIn 0.5s ease-in-out forwards;
    }

    .book-puja-aarti {
      color: $black;
      font-size: 3.125vw;
      font-weight: 600;
      line-height: 3.125vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: fadeIn 0.7s ease-in-out forwards;
    }

    .simplify-rituals {
      margin-top: 0.521vw;
      color: $gray;
      font-size: 1.302vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      animation: fadeIn 0.9s ease-in-out forwards;
    }
  }

  .swipe-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    z-index: 20;
  }

  .pooja-cards-container {
    background-color: $white;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    white-space: nowrap;
    max-width: 100%;
    -ms-overflow-style: none;

    scrollbar-width: none;


    ::-webkit-scrollbar {
      display: none;
    }

    .pooja-cards {
      display: flex;
      transition: transform 0.5s ease-in-out;
      gap: 2.083vw;
      padding: 0 1.563vw;
      margin-bottom: 6vw;
      margin-top: 4vw;



      .no-pooja-cards {
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
        font-size: 1.563vw;
        font-family: 'M PLUS 1';
        color: $orange;

      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

@media (max-width: 638px) {
  .bookpuja-container {
    width: 100%;

    .pujarti-container {
      width: 100%;

      .puja-aarti {
        font-size: 3vw;
      }

      .book-puja-aarti {
        margin-top: 2vw;

        span {
          font-size: 4vw !important;
          line-height: 4vw;
        }
      }

      .simplify-rituals {
        margin-top: 2vw;

        span {
          font-size: 2vw;
        }
      }
    }

    .pooja-cards-container {
      width: 100%;

      .pooja-cards {
        width: 100%;
        margin-top: 4vw;

        .pooja-card {
          min-width: 122px;
          border: 2px solid $orange;
          border-radius: 10px;

          .card-inside-image {
            img {
              // width: 28.313vw;
              height: 18.115vw;
            }
          }

          .card-inside-info {
            .card-title {
              font-size: 2.302vw;
            }

            .card-subtitle,
            .card-location {
              font-size: 1.7vw;
            }
          }

          .pooja-date {
            padding: 2.5vw;
            font-size: 2.406vw;
          }

          .book-puja-button {
            width: 40%;
            height: 11%;
            border-radius: 5px;
            bottom: -2.51vw;
            font-size: 1.5vw;
          }
        }
      }

      .swipe-buttons {
        top: 23.417vw;
      }
    }
  }
}